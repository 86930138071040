module.exports = {
  siteTitle: 'IL2PNW', // <title>
  manifestName: 'IL2PNW',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Welcome to : I Live to Praise and Worship',
  subHeading:
    'Website is Comming Soon. You can Signup to be notified when we go LIVE',
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/ILiveToPraiseAndWorship',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:ILiveToPraiseAndWorship@gmail.com',
    },
  ],
};
